<template>
  <b-container fluid class="py-5">
    <b-card>
      <b-row>
        <b-col class="text-center mb-3">
          <strong>Para autenticar su cuenta necesitará proveer la siguiente información</strong>
        </b-col>
      </b-row>
      <b-card
        @click="uploadFile('dniFront')"
        class="buttonCard p-0 align-middle"
      >
        <b-row align-v="center">
          <b-col cols="2" class="icon-token-dni">
            <!-- <img src="@/assets/dni-frente.svg"> -->
            <dni-frente :color="getMainColor" />
          </b-col>
          <b-col cols="6">
            <b>Prueba de Identidad</b> <br />
            Foto DNI - Frente
          </b-col>
          <b-col class="text-right" cols="4">
            <i
              class="text-success fas fa-check-circle fa-2x"
              v-if="form.data.dniFront"
            ></i>
            <span class="instantanea" v-else>Instantáneo</span>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        @click="uploadFile('dniBack')"
        class="buttonCard my-2 p-0 align-middle"
      >
        <b-row align-v="center">
          <b-col cols="2">
            <!-- <img src="@/assets/dni-dorso.svg"> -->
            <dni-dorso :color="getMainColor" />
          </b-col>
          <b-col cols="6">
            <b>Prueba de Identidad</b> <br />
            Foto DNI - Dorso
          </b-col>
          <b-col class="text-right" cols="4">
            <i
              class="text-success fas fa-check-circle fa-2x"
              v-if="form.data.dniBack"
            ></i>
            <span class="instantanea" v-else>Instantáneo</span>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        @click="uploadFile('dniAndSelfie')"
        class="buttonCard my-2 p-0 align-middle"
      >
        <b-row align-v="center">
          <b-col cols="2">
            <!-- <img src="@/assets/prueba-vida.svg"> -->
            <prueba-vida :color="getMainColor" />
          </b-col>
          <b-col cols="6">
            <b>Prueba de Vida</b> <br />
            Selfie con DNI
          </b-col>
          <b-col class="text-right" cols="4">
            <i
              class="text-success fas fa-check-circle fa-2x"
              v-if="form.data.dniAndSelfie"
            ></i>
            <span class="instantanea" v-else>Instantáneo</span>
          </b-col>
        </b-row>
      </b-card>
      <b-button
        block
        class="uppercase my-4"
        @click="confirmStep"
        :disabled="imageMissing"
      >
        <span class="my-2 form-text">VALIDAR CUENTA</span>
      </b-button>
        <b-row>
          <stepper class="stepper" :filled="currentStep" :size="maxSteps"/>
        </b-row>
    </b-card>

    <b-modal hide-footer id="picture-modal" title="Subir Imagen">
      <b-row v-if="!optionSelected" align-h="center" class="py-2"
        ><h4><strong></strong></h4
      ></b-row>
      <b-row align-h="center" class="py-2"
        >Seleccione la opción que desee para continuar</b-row
      >
      <b-row align-h="center" class="py-2">
        <b-col cols="6">
          <b-card
            class="buttonCard"
            id="takeAPhoto"
            @click="optionSelected = 'camera'"
          >
            <b-card-text class="text-center m-0 p-0">
              <i class="text-dark fas fa-camera fa-3x" />
            </b-card-text>
          </b-card>
          <b-tooltip
            placement="bottom"
            variant="primary"
            target="takeAPhoto"
            triggers="hover"
          >
            Tomar fotografía
          </b-tooltip></b-col
        >
        <b-col cols="6"
          ><b-card
            class="buttonCard"
            id="uploadAFile"
            @click="optionSelected = 'file'"
          >
            <b-card-text class="text-center">
              <i class="text-dark fas fa-file-upload fa-3x" />
            </b-card-text>
          </b-card>
          <b-tooltip
            placement="bottom"
            variant="primary"
            target="uploadAFile"
            triggers="hover"
          >
            Subir un Archivo
          </b-tooltip></b-col
        >
      </b-row>
      <CameraUi
        v-if="optionSelected === 'camera'"
        @confirm-file="setFile"
      ></CameraUi>
      <ImageUploader
        :uploadFile="optionSelected"
        @file-browser-open="optionSelected = ''"
        @confirm-file="setFile"
      ></ImageUploader>
    </b-modal>
  </b-container>
</template>

<script>
import stepsMixin from '@/mixins/stepsMixin'
import { mapGetters } from 'vuex'

export default {
  name: "UploadDni",
  components: {
    CameraUi: () => import("@/components/CameraUI.vue"),
    ImageUploader: () => import("@/components/ImageUploader.vue"),
    Stepper: () => import('@/views/Components/Giftcards/Stepper'),
    DniDorso: () => import('@/components/icons/dni-dorso'),
    DniFrente: () => import('@/components/icons/dni-frente'),
    PruebaVida: () => import('@/components/icons/prueba-vida')
  },
  mixins: [stepsMixin],
  data: () => {
    return {
      imageData: "",
      form: {
        data: { dniFront: "", dniBack: "", dniAndSelfie: "" },
      },
      optionSelected: "",
      fileToUpload: "",
    }
  },

  methods: {
    splitter(string) {
      const partial = string.split(",")
      const extension = partial[0].split(";")[0].split("/")[1]
      const source = partial[1]
      return { extension, source }
    },
    confirmStep() {
      const dniFront = this.splitter(this.form.data.dniFront)
      const dniBack = this.splitter(this.form.data.dniBack)
      const dniAndSelfie = this.splitter(this.form.data.dniAndSelfie)
      const value = {
        data: {
          dniFront: dniFront.source,
          typeFront: dniFront.extension,
          dniBack: dniBack.source,
          typeBack: dniBack.extension,
          dniAndSelfie: dniAndSelfie.source,
          typeSelfie: dniAndSelfie.extension,
        },
      }
      this.$emit("confirm-step", value)
    },
    setFile(value) {
      if(value.length <= (170*1024) ) { // Máximo un archivo de 170K
        this.form.data[this.fileToUpload] = value
        this.$bvModal.hide("picture-modal")
      } else {
        const notification = {
          message: 'El archivo es demasiado grande, intente con uno de menor tamaño',
          variant: "danger",
          countdown: 5,
        }
        this.$store.dispatch("notifications/notify", {
          notification: notification,
        })        
      }
    },
    uploadFile(filename) {
      this.fileToUpload = filename
      this.$bvModal.show("picture-modal")
    },
  },
  computed: {
    ...mapGetters('user', [
      'getMainColor'
    ]),
    imageMissing() {
      return (
        this.form.data.dniFront === "" ||
        this.form.data.dniBack === "" ||
        this.form.data.dniAndSelfie === ""
      )
    },
  },
}
</script>

<style lang="css" scoped>
.buttonCard:hover {
  background-color: rgb(245, 245, 245);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
/*.buttonCard {
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.2);

  box-shadow: 1px 1px 1px 1px rgb(158, 158, 158);
}*/
.buttonCard {
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  background-color: rgba(255,255,255,0);
  cursor: pointer;
}
.buttonCard:active {
  left: 1px;
  top: 1px;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgba(0, 0, 0, 0.2);

}

.buttonCard:hover svg {
  color: #7952b3;
}
.instantanea {
    /* color: #331A76; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}
</style>
